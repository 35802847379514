<template>
  <div class="page-info">
    <el-scrollbar
      class="g-bg"
      y
    >
      <div
        class="g-p-20"
        v-if="infoData.content"
      >
        <el-row :gutter="10">
          <el-col>
            <b-info-item
              label="内容"
              :value="infoData.content"
            ></b-info-item>
          </el-col>
          <el-col :span="12">
            <b-info-item
              label="创建人"
              :value="infoData.user.name"
            ></b-info-item>
          </el-col>
          <el-col :span="12">
            <b-info-item
              label="创建时间"
              :value="infoData.created_at"
            ></b-info-item>
          </el-col>
          <el-col v-if="infoData.images.length">
            <b-info-item label="图片">
              <c-image-list :list="infoData.images"></c-image-list>
            </b-info-item>
          </el-col>
        </el-row>
        <div class="reply-box">
          <el-avatar
            class="avatar"
            :size="40"
            :src="$store.state.user.userInfo.avatar"
          ></el-avatar>
          <el-form
            class="b-py-10 g-flex-fill"
            ref="form"
            :model="formData"
            :rules="formRules"
          >
            <el-form-item prop="content">
              <el-input
                v-model="formData.content"
                type="textarea"
                :autosize="{minRows: 3}"
                placeholder="输入回复内容"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <c-upload-image v-model="formData.images"></c-upload-image>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                block
                @click="onSubmit"
              >回复</el-button>
            </el-form-item>
          </el-form>
        </div>

        <div
          class="g-empty"
          v-if="replyList.length === 0"
        ></div>
        <div class="reply-list">
          <div
            class="reply-item"
            v-for="item in replyList"
            :key="item.id"
          >
            <el-avatar
              class="avatar"
              :size="40"
              :src="item.user.avatar"
            ></el-avatar>
            <div class="info-box">
              <div class="name">{{ item.user.name }}</div>
              <div class="content">{{ item.content }}</div>
              <div class="img">
                <c-image-list :list="item.images"></c-image-list>
              </div>
              <div class="time">{{ item.created_at }}</div>
            </div>
          </div>

          <div
            class="g-center"
            v-if="page.size * page.current < page.total"
          >
            <el-button
              class="btn"
              type='text'
              @click="onMore"
            >查看更多</el-button>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import CImageList from '@/components/upload/image-list'
import CUploadImage from '@/components/upload/image'
import { getFeedbackDetail, getFeedbackReplyPage, createFeedbackReply } from '@/api/feedback'
export default {
  components: {
    CImageList,
    CUploadImage,
  },
  data() {
    return {
      id: null,
      infoData: {},
      replyList: [],
      page: {
        current: 1,
        size: 15,
        total: 0,
      },
      formData: {
        content: '',
        images: [],
      },
      formRules: {
        content: [{ required: true, message: '必填项', trigger: 'blur' }],
      },
    }
  },
  watch: {
    '$route.query.id': {
      handler(id) {
        if (id) {
          this.id = id
          this.fetchData()
          this.fetchReplyList()
        }
      },
      immediate: true,
    },
  },
  methods: {
    fetchData() {
      getFeedbackDetail(this.id).then(res => {
        this.infoData = res
      })
    },
    fetchReplyList() {
      const params = {
        id: this.id,
        current_page: this.page.current,
        current_count: this.page.size,
      }
      getFeedbackReplyPage(params).then(res => {
        this.replyList = res.data
        this.page.total = res.total
      })
    },
    onMore() {
      this.page.current++
      this.fetchReplyList()
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          createFeedbackReply(this.id, this.formData).then(res => {
            this.$message.success('回复成功')
            this.page.current = 1
            this.formData.images = []
            this.formData.content = ''
            this.fetchReplyList()
          })
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.page-info {
  height: 100%;
  padding: 0 15px 15px 15px;

  .reply-box {
    display: flex;
    margin-top: 40px;

    .avatar {
      margin: 0 40px;
    }
  }

  .reply-list {
    .reply-item {
      display: flex;

      .avatar {
        margin: 0 40px;
      }

      .info-box {
        flex: 1;
        min-width: 0;
        padding: 10px 0;
        border-top: 1px solid #ddd;
      }

      .name {
        font-size: 12px;
        font-weight: bold;
        color: #888;
      }

      .content {
        font-size: 14px;
        line-height: 1.4;
      }

      .time {
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
  }
}
</style>
